import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div className="Service">
      <div className="w-full h-44 flex">
        <div className="pt-20 w-full bg-sky-100">
          <h1 className="text-4xl font-bold text-center color">Contact Us</h1>
          <h1 className="text-center font-semibold">You may contact us using the information below</h1>
        </div>
      </div>

      <div className="w-4/5 m-auto mt-5">
        <div className="contact-item mb-3">
          <span className="font-bold">Merchant Legal entity name:</span> Feastic Services Pvt Ltd
        </div>
        <div className="contact-item mb-3">
          <span className="font-bold">Registered Address:</span> 603 Tower A Tarika Apartment Sector 43 Gurgaon Haryana 122001
        </div>
        <div className="contact-item mb-3">
          <span className="font-bold">Operational Address:</span>GF-108H Sushant Shopping Address Sushant Lok-1 Sector 43 Gurugram- 122002
        </div>
        {/* <div className="contact-item mb-3">
          <span className="font-bold"></span> H.N-1010, SECTOR-43, Gurgaon, Gurugram, Haryana, 122002
        </div> */}
        
        <div className="contact-item mb-3">
          <span className="font-bold">E-Mail ID:</span> hello@falhari.com
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
