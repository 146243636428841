// src/components/Timeline.js

import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { motion } from 'framer-motion';
import './Timeline.css'; // Import your custom CSS file

import step1 from '../assets/timelineImages/1.png';
import step2 from '../assets/timelineImages/2.png';
import step3 from '../assets/timelineImages/3.png';
import step4 from '../assets/timelineImages/4.png';
import step5 from '../assets/timelineImages/5.png';
import step6 from '../assets/timelineImages/6.png';
import step7 from '../assets/timelineImages/7.png';

const Timeline = () => {
  const events = [
    { title: "Step 1", description: "Description for step 1", image: step1 },
    { title: "Step 2", description: "Description for step 2", image: step2 },
    { title: "Step 3", description: "Description for step 3", image: step3 },
    { title: "Step 4", description: "Description for step 4", image: step4 },
    { title: "Step 5", description: "Description for step 5", image: step5 },
    { title: "Step 6", description: "Description for step 6", image: step6 },
    { title: "Step 7", description: "Description for step 7", image: step7 },
  ];

  return (
    <div className='pt-20 lg:w-4/5 lg:m-auto relative'>
    <p className='font-bold pb-6 text-lg lg:text-2xl px-2 lg:text-center '>Steps to check the acidity</p>
      <VerticalTimeline className="custom-vertical-timeline">
        {events.map((event, index) => (
          <VerticalTimelineElement
            key={index}
            date={<span className="rounded-full px-4 py-2 bg-theme text-white border-2 hidden lg:inline">Step {index + 1}</span>} // Customized date element

            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            className="custom-vertical-timeline-element"
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.3 }}
            
            className='custom-div'
            >
            {<span className="rounded-full px-4 py-2 bg-theme text-white border-2 lg:hidden">Step {index + 1}</span>}

              <h3 className="vertical-timeline-element-title text-xl hidden lg:block">{event.title}</h3>
              {/* <p>{event.description}</p> */}
              <img src={event.image} alt={`Step ${index + 1}`} className="timeline-image" /> {/* Image in content */}
            </motion.div>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>

      {/* Floating button */}
      <div className="flex fixed bottom-0 py-3 shadow-md lg:py-4 px-2 z-50 left-0 right-0 flex justify-between lg:justify-center bg-white border-t-2">
        <div><p className='lg:mr-4 text-lg font-medium lg:font-bold pt-2 leading-none  '>Proceed to check acidity</p></div>
        <a href="https://app.falhari.com/store/acidity-meter/"  >
        <button className="bg-theme whitespace-nowrap text-md lg:text-md  hover:bg-red-600 text-white px-4 py-2 lg:py-2 Lg:px-4 rounded-lg shadow-lg focus:outline-none">
          Check →
        </button>
        </a>
      </div>
    </div>
  );
};

export default Timeline;
