// CategoryCard.js
import React, { useState } from 'react';
import PropertyImg from '../images/landing.png';
import Collab from '../images/Collab.png';
import Consult from '../images/consult.png';
import Time from "../images/time.png";
import Juices from '../images/Juices.png';
import Lassi from '../images/Lassi.png';
import Smoothie from '../images/Smoothie.png';
import Salad from '../images/Salad.png';
import Shakes from '../images/Shakes.png';
import Bowl from '../images/FruitBowls.png';
import Bot from '../images/bot.png';
import { Link } from 'react-router-dom';
import { IoIosCloseCircleOutline } from "react-icons/io";

const category = [
  {
    imageUrl: Juices,
    heading: "Juices",
    price: "Rs 119",
  },
  {
    imageUrl: Salad,
    heading: "Salad",
    price: "Rs 59",
  },
  {
    imageUrl: Lassi,
    heading: "Lassi",
    price: "Rs 139",
  },
  {
    imageUrl: Smoothie,
    heading: "Smoothie",
    price: "Rs 139",
  },
  {
    imageUrl: Shakes,
    heading: "Shakes",
    price: "Rs 119",
  },
  {
    imageUrl: Bowl,
    heading: "Fruit Bowls",
    price: "Rs 279",
  },
];

const CategoryCard = () => {
  const [launchShowPopup, setLaunchShowPopup] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);

  

  const LaunchingPopup = () => {
    setLaunchShowPopup(!launchShowPopup);
  };

  const showWhatsappPopup = () =>{
    setWhatsappPopup(!whatsappPopup);
  };

  const handleOrderButtonClick = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) {
      LaunchingPopup();
    } else {
      showWhatsappPopup();
    }
  };

  return (
    <div>
      <div className="container justify-center w-fit m-auto px-2 py-2 grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-6">
        {category.map((property, index) => (
          <button key={index} onClick={handleOrderButtonClick} className="border max-w-xs rounded-xl overflow-hidden relative hover:border">
            <div className="border-2 border-orange-400 absolute top-2 left-2 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-90% text-white px-2 rounded-2xl">
              {property.tag}
            </div>
            <img className="w-full h-42" src={property.imageUrl} alt={property.heading} />
            <div className="lg:absolute lg:top-4 lg:left-2 lg:bg-orange-300 lg:bg-opacity-50 color font-medium text-sm text-center lg:text-lg px-2 py-1 rounded-sm lg:rounded-full">
              Starts at<span className='font-bold pl-1 '>{property.price}</span>
            </div>
            {/* Property Details */}
            <div className="px-4 py-4 hidden">
              <div className="mb-2 flex">
                <p className="border px-2 rounded-xl bg-blue-100">{property.flatType}</p>
              </div>
              <div className="font-bold text-xl mb-2">{property.heading}</div>
              <p className="text-gray-700 text-base mb-2">{property.description}</p>
              <button className="border-2 w-full border-gray-800 mb-4 font-medium px-4 py-2 text-white rounded-full bg-gradient-to-r from-sky-400 to-violet-600">
                {property.button}
              </button>
            </div>
          </button>
        ))}
      </div>

      

      {launchShowPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded w-2/5">
            <IoIosCloseCircleOutline className="close-icon absolute top-0 right-0 mt-2 mr-2 cursor-pointer text-black font-bold text-2xl" onClick={LaunchingPopup} />
            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-bold'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>

            <p className='text-xl font-medium text-red-500'>*Note:Please Use Mobile for Better experience</p>
            <button className='bg-theme px-4 py-2 text-white text-semibold rounded' onClick={LaunchingPopup}>OK</button>
          </div>
        </div>
      )}

      {whatsappPopup && (
        <div className="popup-wrapper ">
          <div className="popup-content">
            <IoIosCloseCircleOutline className="close-icon" onClick={showWhatsappPopup} />

            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-medium leading-none'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>
            <Link to='https://wa.me/message/5TJSEZZBZM5LM1'>
            <button  className='bg-theme px-4 py-2 text-white text-semibold rounded w-full'>Order Now</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryCard;
