import React, { useState } from "react";
import Slide1 from '../images/Slide1.JPG';
import Slide2 from '../images/Slide2.JPG';
import Slide3 from '../images/Slide3.JPG';
import Slide4 from '../images/Slide4.JPG';
import Slide5 from '../images/Slide5.JPG';
import Slide6 from '../images/Slide6.JPG';
import Slide7 from '../images/Slide7.JPG';
import Slide8 from '../images/Slide8.JPG';
import Slide9 from '../images/Slide9.JPG';
import Slide10 from '../images/Slide10.JPG';
import Slide11 from '../images/Slide11.JPG';
import Slide12 from '../images/Slide12.JPG';
import Slide13 from '../images/Slide13.JPG';



import './About.css'; 


function About(){
    const [sliderIndex, setSliderIndex] = useState(0);

    const adDetails = {
        images: [
            Slide1,
            Slide2,
            Slide3,
            Slide4,
            Slide5,
            Slide6,
            Slide7,
            Slide8,
            // Slide9,
            Slide10,
            Slide11,
            Slide12,
            Slide13,

            // Add more image imports if needed
        ]
    };

    const handleClick = (index) => {
        setSliderIndex(index);
    };

    return(
        <div className="">
           <div className="w-full h-44 ">
                <div className="pt-20">
                    <h1 className="text-6xl font-bold color text-center text-black">Falhari</h1>
                    <h1 className="text-center font-semibold">Indian No.1 Fresh Fruits brand</h1>
                </div>
           </div>

            <div className='lg:w-3/5 m-2 flex flex-col lg:m-auto relative'>
              <div className='w-full p-2 bg-[#EBEEF7] mb-2 relative'>
                  <img src={adDetails.images[sliderIndex]} alt='images' className='object-cover m-auto h-fit md:h-[500px] w-full' />
                  
                  
              </div>

              <div className='flex flex-row gap-4 rounded-md overflow-x-auto no-scrollbar'>
                  {adDetails.images.map((image, i) => (
                      <img key={i} src={image} onClick={() => { handleClick(i) }} className={`h-20 w-20 md:h-28 md:w-28 object-cover rounded-md border-2 ${i === sliderIndex ? 'border-2 border-blue-500' : ''}`} alt='images' />
                  ))}
              </div>
            </div> 
        </div>
    );
}

export default About;
