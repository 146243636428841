// WriteDataToFirestore.js

import React, { useEffect } from "react";
import { db } from "../firebase";
import data from "./data"; // Import data to write
import { collection, setDoc, doc } from "firebase/firestore";


function WriteDataToFirestore() {
  useEffect(() => {
    const writeDataToFirestore = async () => {
      try {
        console.log(data)
        for (const docId in data) {
          if (Object.hasOwnProperty.call(data, docId)) {
            const docRef = doc(collection(db, "blog"), docId);
            await setDoc(docRef, data[docId]);            
            console.log(`Document ${docId} written to Firestore.`);
          }
        }
        console.log('All data written to Firestore.');
      } catch (error) {
        console.error('Error writing data to Firestore:', error);
      }
    };

    writeDataToFirestore(); // Call the function when the component mounts
  }, []); // Empty dependency array ensures it only runs once on mount

  return null; // This component doesn't render anything
}

export default WriteDataToFirestore;
