import React, { useEffect, useState } from "react";
import "./Search.css";
import ProductData from "./db/ProductsDB/ProductsData"; // Assuming this contains the product data
import { IoReloadCircleOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";

const SearchContainer = ({ setFilteredProducts, setSelectedTag }) => {
  const [randomTags, setRandomTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTags, setShowTags] = useState(false);

  const getRandomTags = () => {
    const allTags = ProductData.flatMap((product) => product.tags);
    const uniqueTags = [...new Set(allTags)];
    const shuffledTags = uniqueTags.sort(() => 0.5 - Math.random());
    return shuffledTags.slice(0, 10);
  };

  const loadMoreTags = () => {
    setIsLoading(true);
    setTimeout(() => {
      setRandomTags(getRandomTags());
      setIsLoading(false);
    }, 300);
  };

  const handleTagClick = (tag) => {
    const isTagSelected = selectedTags.includes(tag);

    if (isTagSelected) {
      // If tag is already selected, remove it
      const updatedTags = selectedTags.filter((t) => t !== tag);
      setSelectedTags(updatedTags);
      setSelectedTag(updatedTags); // Pass updated tags to parent component
    } else if (selectedTags.length < 3) {
      // If tag is not selected and less than 3 tags are selected, add it
      const updatedTags = [...selectedTags, tag];
      setSelectedTags(updatedTags);
      setSelectedTag(updatedTags); // Pass updated tags to parent component
    }
  };

  const filterProducts = () => {
    if (selectedTags.length === 0) {
      setFilteredProducts(ProductData);
      return;
    }

    const filtered = ProductData.filter((product) =>
      selectedTags.some((tag) => product.tags.includes(tag))
    );
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    setRandomTags(getRandomTags());
  }, []);

  const handleSearchClick = () => {
    setShowTags((prevShowTags) => !prevShowTags);
    filterProducts();
  };

  return (
    <div className="Search">
      <div className="search-container">
        <div>
          <p className="lg:text-3xl text-2xl mb-4 font-bold text-center m-auto">
            Fruit products for your health goals <br />
            <span className="color">#Falhari</span>
          </p>
        </div>

        <div className="search-bar mb-4">
          <div className="search-input-container rounded-lg px-2 w-5/6 focus-within:border-none transition duration-300 flex items-center gap-2">
            <div className="selected-tags-container flex overflow-x-auto items-center gap-2">
              {selectedTags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-white text-black border-2 px-3 py-1 rounded-full cursor-pointer flex items-center whitespace-nowrap"
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                  <span className="ml-2">&times;</span>
                </span>
              ))}
            </div>
            <input
              type="text"
              className="focus:outline-none"
              value=""
              readOnly // Make input field read-only
              onClick={handleSearchClick} // Allow clicking to show tag options
              // placeholder="Select up to 3 tags"
            />
          </div>

          <button
            className="hidden lg:block lg:w-1/6 bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition duration-300"
            onClick={handleSearchClick}
          >
            {"Search"}
          </button>
          <button
            className="lg:hidden w-fit lg:w-1/6 bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition duration-300"
            onClick={handleSearchClick}
          >
          <FaSearch size={20}/>
            {""}
          </button>
        </div>

        {showTags && (
          <>
          <p className="text-xl font-semibold color">Select upto 3 tags</p>

            <div
              className={`tag-container mt-5 flex flex-wrap justify-center gap-2 md:gap-4 w-full md:w-4/5 transition-all duration-500 ${
                isLoading ? "opacity-0" : "opacity-100"
              }`}
            >
              {randomTags.map((tag, index) => (
                <span
                  className={`bg-white px-2 py-1 rounded-full shadow cursor-pointer ${
                    selectedTags.includes(tag) ? "bg-orange-300" : ""
                  } ${selectedTags.length >= 3 && !selectedTags.includes(tag) ? "cursor-not-allowed opacity-50" : ""}`} // Disable unselected tags when 3 are selected
                  key={index}
                  onClick={() => handleTagClick(tag)}
                >
                  <span className="bg-gradient-to-r from-blue-500 to-purple-500 text-black font-semibold py-2 px-4 rounded-full border-1 bg-clip-text text-transparent animate-shimmer">
                    {tag}
                  </span>
                </span>
              ))}
            </div>

            <div className="flex justify-center mt-4 shadow bg-white rounded-full mb-18 cursor-pointer transition duration-300 transform active:scale-90">
              <div className="pt-2 pl-2 text-orange-500 transition duration-300 transform active:scale-90 active:bg-yellow-600">
                <IoReloadCircleOutline size={24} />
              </div>
              <div
                className="bg-gradient-to-r from-orange-500 to-yellow-500 text-black font-semibold py-2 pr-4 rounded-full border-1 bg-clip-text text-transparent"
                onClick={loadMoreTags}
              >
                Load More Tags
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchContainer;
