import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import data from './data';
import { CiShare2 } from "react-icons/ci";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoMdShare } from "react-icons/io";
import { CiViewList } from "react-icons/ci";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { BsViewList } from "react-icons/bs";

function BlogDetail() {
  const { id } = useParams();
  const blog = data[id];
  const [showBlogList, setShowBlogList] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const toggleBlogList = () => {
    setShowBlogList(!showBlogList);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: blog.title,
        text: blog.overview,
        url: window.location.href,
      }).then(() => {
        console.log('Blog shared successfully');
      }).catch((error) => {
        console.error('Error sharing blog:', error);
      });
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <div className='p-3'>

    <div className='lg:w-3/5 lg:m-auto'>
      <div className='pt-16'>
      <div className='flex justify-between'>
      <Link to='/blogs'>
      <div className='pb-2'><IoArrowBackOutline className='text-xl text-black' /></div>
      </Link>
      <div onClick={handleShare} className='pb-2'><IoMdShare className='text-xl text-black' /></div>
      </div>
        <h1 className='text-2xl font-bold'>{blog.title}</h1>
      </div>
      {/* <div className='w-full h-1 bg-theme mt-4'></div> */}
      <p className='my-4'>
        {blog.tags.map(tag => (
          <span key={tag} className="border bg-sky-100 text-blue-500 mr-2">#{tag} </span>
        ))}
      </p>
      <div className='w-full h-1 bg-theme my-4 rounded-full'></div>

      <h1 className='text-xl font-bold'>Overview</h1>
      <p className='py-2 '>{blog.overview}</p>
      
      <div className='py-4'>
        {blog.subheading.map((subh, index) => (
          <div key={index}>
            <h2 className='text-lg font-semibold pt-8'>{subh}</h2>
            <p className='py-2'>{blog.paragraph[index]}</p>
          </div>
        ))}
      </div>
      <p className='text-xl font-bold py-4'>Summary</p>
      <p>{blog.summary}</p>

      <div className='mt-6'>
        <button
          onClick={handleShare}
          className='border-2 color text-black px-4 py-2 rounded shadow flex'
        >
          <CiShare2 className='font-bold text-xl mx-2 pt-1'/>Share this blog
        </button>
      </div>
      </div>

      <button
        onClick={toggleBlogList}
        className='z-50 fixed bottom-4 right-2 bg-theme text-white p-2 rounded-full shadow-lg mb-12 text-2xl'
      >
        {showBlogList ? <IoMdCloseCircleOutline/> : <BsViewList/>}
      </button>

      {showBlogList && (
        <div className={`fixed bottom-12 right-0 bg-white p-4  shadow-lg shadow-slate-800 lg:shadow-md  border-t-2 rounded-lg  max-h-64 pb-8 overflow-y-auto ${animateOut ? 'slide-out' : 'slide-in'}`}>
          <div className='flex justify-between items-center mb-2'>
            <h2 className='text-md font-semibold'>More Blogs</h2>
            {/* <button
              onClick={toggleBlogList}
              className='text-red-500 hover:text-red-700'
            >
              Close
            </button> */}
          </div>
          <ul>
          {Object.keys(data).map((key, index) => (
                <li key={key} className='mb-3 flex items-center'>
                <div className='w-8 h-8 bg-theme text-center mr-2 flex items-center justify-center text-white'>
                  {index + 1}
                </div>
                <Link to={`/blog/${key}`} className='text-blue-500 h-8  leading-none hover:underline text-truncate'>
                  {data[key].title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
      
    </div>
  );
}

export default BlogDetail;
